import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify'; // Import toast from react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Import react-toastify CSS
import './LeftSection.css'; // Ensure this file contains the provided styles
import { nanoid } from 'nanoid'; // Import nanoid

const LeftSection = () => {
  const [roleName, setRoleName] = useState('');
  const [chatHistory, setChatHistory] = useState([]); // Chat history state
  const navigate = useNavigate();

  useEffect(() => {
    const role = localStorage.getItem('role_name');
    setRoleName(role);

    // Load initial chat history from localStorage
    updateChatHistory();

    // Add event listener for updating chat history
    window.addEventListener('updateChatHistory', updateChatHistory);

    return () => {
      // Remove event listener on component unmount
      window.removeEventListener('updateChatHistory', updateChatHistory);
    };
  }, []);

  const updateChatHistory = () => {
    const keys = Object.keys(localStorage);
    const chats = keys.filter(key => key.startsWith('chat_')).map(key => {
      const chatData = JSON.parse(localStorage.getItem(key));
      return {
        id: key.replace('chat_', ''),
        firstQuestion: chatData[0]?.text || '',
        type: chatData[0]?.type || 'documents' // Default to 'documents' if type is not available
      };
    }).filter(chat => chat.firstQuestion !== ''); // Filter out entries with empty questions
    setChatHistory(chats);
  };

  const onMenuItemContainerClick = (path) => {
    navigate(path);
  };

  const onBotsContainerClick = (path) => {
    navigate(path);
  };

  const getIcon = (type) => {
    switch (type) {
      case 'documents':
        return '/vector1.svg'; // Path to the documents icon
      case 'database':
        return '/vector2.svg'; // Path to the database icon
      default:
        return '/vector2.svg'; // Fallback icon
    }
  };

  const handleDeleteChat = (chatId, index) => {
    localStorage.removeItem(`chat_${chatId}`);
    setChatHistory(chatHistory.filter((_, i) => i !== index));
    toast.success("Chat history deleted successfully."); // Show toast message
  };

  return (
    <div className="left-content6">
      <div className="bots15">
        <div className="menu-item13" onClick={() => onMenuItemContainerClick(`/chatpage-documents/${nanoid()}`)}>
          <div className="logo6">
            <div className="vector-wrapper6">
              <img className="vector-icon40" alt="" src="/vector.svg" />
            </div>
            <div style={{ fontSize: 14 }} className="choose-document">Documents</div>
          </div>
          <img
            className="open-in-new-24dp-5f6368-fill0-icon13"
            alt=""
            src="/open-in-new-24dp-5f6368-fill0-wght400-grad0-opsz24-1.svg"
          />
        </div>

        <div className="menu-item13" onClick={() => onMenuItemContainerClick(`/chatpage-database/${nanoid()}`)}>
          <div className="frame-parent33">
            <div className="vector-parent29">
              <img className="vector-icon41" alt="" src="/vector.svg" />
              <img
                className="vector-icon40"
                alt=""
                src="/database-24dp-5f6368-fill0-wght400-grad0-opsz24-1.svg"
              />
            </div>
            <div style={{ fontSize: 14 }} className="choose-document">Database</div>
          </div>
          <img
            className="open-in-new-24dp-5f6368-fill0-icon13"
            alt=""
            src="/open-in-new-24dp-5f6368-fill0-wght400-grad0-opsz24-1.svg"
          />
        </div>
      </div>
      {roleName === 'Admin' && (
        <div className="manage5">
          <div className="bots16" onClick={() => onBotsContainerClick("/homepage-document-bot1")}>
            <div style={{ fontSize: 14 }} className="choose-document">Manage Documents</div>
            <img
              className="arrow-right-24dp-5f6368-fill0-icon12"
              alt=""
              src="/arrow-right-24dp-5f6368-fill0-wght400-grad0-opsz24-1.svg"
            />
          </div>
          <div className="bots17" onClick={() => onBotsContainerClick("/homepage-database-bot1")}>
            <div style={{ fontSize: 14 }} className="choose-document">Manage Databases</div>
            <img
              className="arrow-right-24dp-5f6368-fill0-icon12"
              alt=""
              src="/arrow-right-24dp-5f6368-fill0-wght400-grad0-opsz24-1.svg"
            />
          </div>
        </div>
      )}
      <div className="chat-history8">
        <div style={{ fontSize: 16, marginBottom: 10, marginTop: 20 }} className="sales-happened-in">Chat history</div>
        {chatHistory.map((chat, index) => (
          <div key={index} className="frame-parent27" onClick={() => navigate(`/chatpage-${chat.type}/${chat.id}`)}>
            <div className="logo5">
              <img className="vector-icon32" alt="" src={getIcon(chat.type)} />
              <div className="sales-happened-in">{chat.firstQuestion}</div> {/* Apply the CSS class here */}
            </div>
            <img
              className="open-in-new-24dp-5f6368-fill0-icon11"
              alt=""
              src="/delete-24dp-5f6368-fill0-wght400-grad0-opsz24-1-1.svg"
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteChat(chat.id, index);
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LeftSection;
