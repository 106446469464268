import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify'; // Import toast from react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Import react-toastify CSS
import "./HomepageDocumentBOT.css";
import NavBar from '../components/NavBar';
import LeftSection from '../components/LeftSection';

const API_BASE_URL = 'https://chatbotpdfapi.stellifyflows.in/';

const HomepageDocumentBOT = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [alertMsg, setAlertMsg] = useState(false);
  const [myMsg, setMsg] = useState("");
  const [isDragging, setIsDragging] = useState(false);
  const [isUploading, setIsUploading] = useState(false); // New state for uploading
  const navigate = useNavigate();

  useEffect(() => {
    fetchDocuments();
  }, []);

  const fetchDocuments = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found');

      const response = await axios.get(`${API_BASE_URL}api/documents`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setDocuments(response.data);
    } catch (error) {
      console.error('Error fetching documents:', error.response ? error.response.data : error.message);
    }
  };

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
    await handleUpload(files); // Automatically upload files after selection
  };

  const handleUpload = async (files) => {
    if (files.length === 0) {
      toast.error("Please select a file first.");
      return;
    }

    const uploadToastId = toast.loading("Uploading..."); // Show loading toast

    setIsUploading(true); // Start loading state

    const formData = new FormData();
    files.forEach(file => formData.append('files', file));

    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found');

      const response = await axios.post(`${API_BASE_URL}api/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        },
      });

      if (response.status === 200) {
        setSelectedFiles([]);
        toast.update(uploadToastId, { render: 'Documents uploaded successfully.', type: 'success', isLoading: false, autoClose: 3000 }); // Update to success toast
        fetchDocuments();
      } else {
        throw new Error('Failed to upload documents.');
      }
    } catch (error) {
      console.error('Error uploading the files:', error.response ? error.response.data : error.message);
      toast.update(uploadToastId, { render: 'Failed to upload documents.', type: 'error', isLoading: false, autoClose: 3000 }); // Update to error toast
    } finally {
      setIsUploading(false); // Reset loading state
    }
  };

  const handleRemove = async (documentId) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found');

      const response = await axios.delete(`${API_BASE_URL}api/documents/${documentId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.status === 204) {
        toast.success('Document deleted successfully.'); // Show success toast for deletion
        fetchDocuments(); // Refresh document list
      } else {
        console.error('Failed to delete document:', response);
      }
    } catch (error) {
      console.error('Error removing the file:', error.response ? error.response.data : error.message);
      toast.error('Failed to delete document.'); // Show error toast for deletion failure
    }
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    setIsDragging(false);
    const files = Array.from(event.dataTransfer.files);
    setSelectedFiles(files);
    await handleUpload(files);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const onUserContainerClick = useCallback(() => {
    navigate("/login-screen");
  }, [navigate]);

  const onMenuItemContainerClick = useCallback(() => {
    navigate("/homepage-document-bot");
  }, [navigate]);

  const onMenuItemContainerClick1 = useCallback(() => {
    navigate("/homepage-database-bot");
  }, [navigate]);

  const onBotsContainerClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onOpenInNew24dp5F6368FILL0IconClick = useCallback(() => {
    navigate("/chatpage-documents");
  }, [navigate]);

  return (
    <div className="homepage-document-bot">
      <NavBar />
      <div className="main-boduy4">
        <LeftSection />
        <div className="right-content3">
          <div className="play-area3">
            <div className="document-selected3">
              <div className="vector-wrapper3">
                <img className="vector-icon24" alt="" src="/vector.svg" />
              </div>
              <div style={{ fontSize: 16 }} className="uploaded-documents">Manage Documents</div>
            </div>
            <div
              className={`upload-documents1 ${isDragging ? 'dragging' : ''}`}
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
            >
              <img
                className="cloud-upload-24dp-5f6368-fill0-icon"
                alt=""
                src="/cloud-upload-24dp-5f6368-fill0-wght400-grad0-opsz24-1.svg"
              />
              <div className="drag-drop">{`Drag & Drop to Upload files`}</div>
              <div className="drag-drop">OR</div>
              <div style={{ cursor: "pointer" }} className="menu-item9">
                <div className="browse-files-parent">
                  {isUploading ? (
                    <div className="drag-drop">Uploading...</div> // Show uploading text
                  ) : (
                    <div className="drag-drop">Browse files</div> // Show browse files text
                  )}
                  <input
                    type="file"
                    multiple
                    onChange={handleFileChange}
                    className="file-input"
                    accept=".pdf,.doc,.docx,.ppt,.pptx,.txt" // Restrict file types
                    style={{
                      position: 'absolute',
                      opacity: 0,
                      cursor: 'pointer',
                    }}
                    disabled={isUploading} // Disable file input while uploading
                  />
                  <div className="upload-24dp-5f6368-fill0-wght4-wrapper">
                    <img
                      className="upload-24dp-5f6368-fill0-wght4-icon"
                      alt=""
                      src="/upload-24dp-5f6368-fill0-wght400-grad0-opsz24-1.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="documents6">
              <div className="title1">
                <div style={{ fontSize: 14 }} className="uploaded-documents">Uploaded Documents</div>
              </div>
              <div className="table-th-group">
                <div className="table-th1">
                  <div className="th-item12">
                    <div style={{ fontSize: 12 }} className="drag-drop">S.no</div>
                  </div>
                  <div className="th-item13">
                    <div style={{ fontSize: 12 }} className="document-name">Document name</div>
                  </div>
                  <div className="th-item14">
                    <div style={{ fontSize: 12 }} className="document-name">Actions</div>
                  </div>
                </div>
                {documents.map((doc, index) => (
                  <div className="table-th1" key={doc.id}>
                    <div className="th-item12">
                      <div style={{ fontSize: 11 }} className="div7">{index + 1}</div>
                    </div>
                    <div className="th-item13">
                      <div style={{ fontSize: 12 }} className="cyber-security">{doc.name}</div>
                    </div>
                    <div className="th-item17">
                      <img
                        className="visibility-24dp-5f6368-fill0-w-icon"
                        alt=""
                        src="/visibility-24dp-5f6368-fill0-wght400-grad0-opsz24-1.svg"
                      />
                      <img
                        className="download-24dp-5f6368-fill0-wgh-icon"
                        alt=""
                        src="/download-24dp-5f6368-fill0-wght400-grad0-opsz24-1.svg"
                      />
                      <img
                        className="open-in-new-24dp-5f6368-fill0-icon6"
                        alt=""
                        src="/delete-24dp-5f6368-fill0-wght400-grad0-opsz24-1-11.svg"
                        onClick={() => handleRemove(doc.id)}
                      />
                      <img
                        className="open-in-new-24dp-5f6368-fill0-icon8"
                        alt=""
                        src="/open-in-new-24dp-5f6368-fill0-wght400-grad0-opsz24-11.svg"
                        onClick={onOpenInNew24dp5F6368FILL0IconClick}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="qtn-area3">
            <div className="uploaded-documents">Ask your question here...</div>
            <img
              className="download-24dp-5f6368-fill0-wgh-icon"
              alt=""
              src="/send-24dp-5f6368-fill0-wght400-grad0-opsz24-1.svg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomepageDocumentBOT;
