import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./HomepageDatabaseBOT1.css";
import NavBar from '../components/NavBar';
import LeftSection from '../components/LeftSection';

const HomepageDatabaseBOT1 = () => {
  const navigate = useNavigate();

  const onMenuItemContainerClick = useCallback(() => {
    navigate("/homepage-document-bot");
  }, [navigate]);

  const onBotsContainerClick = useCallback(() => {
    navigate("/homepage-document-bot1");
  }, [navigate]);

  const onBotsContainerClick1 = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onFormFieldContainerClick = useCallback(() => {
    navigate("/chatpage-database");
  }, [navigate]);

  return (
    <div className="homepage-database-bot1">
     <NavBar />
      <div className="main-boduy2">
        <LeftSection />
        <div className="right-content1">
          <div className="play-area1">
            <div style={{marginBottom:20}} className="document-selected1">
              <div className="vector-parent4">
                <img className="vector-icon9" alt="" src="/vector.svg" />
                <img
                  className="database-24dp-5f6368-fill0-wgh-icon1"
                  alt=""
                  src="/database-24dp-5f6368-fill0-wght400-grad0-opsz24-1.svg"
                />
              </div>
              <div  style={{fontSize:14}} className="choose-database">Choose Database</div>
            </div>
            <div className="documents3">
              <div className="document-tile">
                <div  style={{fontSize:14}} className="choose-database">Employee DB</div>
              </div>
              <div className="document-tile">
                <div  style={{fontSize:14}} className="choose-database">Analysis Data</div>
              </div>
              <div className="document-tile">
                <div  style={{fontSize:14}} className="choose-database">Sales Management DB</div>
              </div>
              <div className="document-tile3">
                <div  style={{fontSize:14}} className="choose-database">Purchase Management</div>
              </div>
            </div>
            <div className="form-field8" onClick={onFormFieldContainerClick}>
              <div className="ask-questions-parent">
                <div  style={{fontSize:14}} className="ask-questions">Chat with your DB</div>
                <img
                  className="login-24dp-5f6368-fill0-wght40-icon"
                  alt=""
                  src="/login-24dp-5f6368-fill0-wght400-grad0-opsz24-1.svg"
                />
              </div>
            </div>
          </div>
          <div className="qtn-area1">
            <div className="choose-database">Ask your question here...</div>
            <img
              className="send-24dp-5f6368-fill0-wght400-icon1"
              alt=""
              src="/send-24dp-5f6368-fill0-wght400-grad0-opsz24-1.svg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomepageDatabaseBOT1;
