import React from 'react'

function Header() {
  return (
    <div className="header1">
    <div className="logo1">
      <img className="layer-1-icon1" alt="" src="/layer-11@2x.png" />
      <div className="email-address" style={{fontSize:"18px"}}></div>
    </div>
    <div className="menu1">
      <div  style={{fontSize:14}} className="email-address">About us</div>
      <div  style={{fontSize:14}} className="email-address">Contact us</div>
    </div>
  </div>
  )
}

export default Header
