import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import Stellify from '../images/Stellify.png';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import './ChatpageDatabase.css';
import NavBar from '../components/NavBar';
import LeftSection from '../components/LeftSection';

const API_BASE_URL = 'https://stellifybot-ai-api.stellifyflows.in/'; // Ensure the correct port

const ChatpageDatabase = () => {
  const { sessionId } = useParams(); // Extract sessionId from URL parameters
  const [question, setQuestion] = useState('');
  const [conversation, setConversation] = useState([]); // Initialize as an empty array
  const [loading, setLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [showTypingEffect, setShowTypingEffect] = useState(false);
  const playAreaRef = useRef(null); // Ref for the play area
  const navigate = useNavigate();

  useEffect(() => {
    const storedConversation = JSON.parse(localStorage.getItem(`chat_${sessionId}`)) || [];
    setConversation(storedConversation);
    setShowTypingEffect(false); // Disable typing effect for existing conversations
  }, [sessionId]);

  useEffect(() => {
    localStorage.setItem(`chat_${sessionId}`, JSON.stringify(conversation));
    // Notify LeftSection to update chat history
    window.dispatchEvent(new Event('updateChatHistory'));
  }, [conversation, sessionId]);

  const scroll = () => {
    if (playAreaRef.current) {
      playAreaRef.current.scrollTop = playAreaRef.current.scrollHeight; // Scroll to the bottom
    }
  };

  useEffect(() => {
    scroll();
  }, [conversation, isTyping]); // Trigger scrolling when conversation changes or typing

  const handleQuestionChange = (event) => {
    setQuestion(event.target.value);
  };

  const handleTypingComplete = () => {
    setIsTyping(false); // Mark typing as complete
  };

  // Retry function for API call
  const retryApiCall = async (url, data, retries = 3, delay = 1000) => {
    for (let attempt = 1; attempt <= retries; attempt++) {
      try {
        const response = await axios.post(url, data);
        return response;
      } catch (error) {
        console.error(`Attempt ${attempt} failed:`, error);

        if (attempt === retries) {
          throw new Error('Maximum retries reached');
        }

        // Wait for a specified time before retrying
        await new Promise((resolve) => setTimeout(resolve, delay));
      }
    }
  };

  const handleAskQuestion = async () => {
    if (question.trim() === '') return;

    setLoading(true);
    const userMessage = { role: 'user', text: question, type: 'database' };
    setConversation((prevConversation) => [...prevConversation, userMessage]);
    setQuestion('');

    try {
      const response = await retryApiCall(`${API_BASE_URL}ask`, { question, type: 'database' });

      let botMessage;
      if (response.data.type === 'image_output') {
        const imageUrl = response.data.answer;
        botMessage = { role: 'bot', parts: [{ img: imageUrl }] };
      } else if (response.data.type === 'dataframe_output') {
        const jsonResponse = JSON.parse(response.data.answer);
        botMessage = { role: 'bot', parts: [{ json: jsonResponse }] };
      } else {
        botMessage = { role: 'bot', parts: [{ text: response.data.answer.toString() }] };
      }

      setShowTypingEffect(true);

      setConversation((prevConversation) => [...prevConversation, botMessage]);
    } catch (error) {
      console.error('Error after retries:', error);
      const errorMessage = { role: 'bot', parts: [{ text: 'Sorry, something went wrong. Please try again later.' }] };
      setConversation((prevConversation) => [...prevConversation, errorMessage]);
    } finally {
      setLoading(false);
      setIsTyping(true);
    }
  };

  const handleKeyPress = async (e) => {
    if (e.key === 'Enter' && !loading && question.trim() !== '') {
      await handleAskQuestion();
    }
  };

  return (
    <div className="chatpage-documents">
      <NavBar />
      <div className="main-boduy3">
        <LeftSection />
        <div className="right-content2">
          {conversation.length > 0 ? (
            <div className="play-area2" ref={playAreaRef}> {/* Add ref here */}
              {conversation.map((conv, index) => (
                <div key={index} className="message" style={{ width: '100%', marginBottom: '10px' }}>
                  <img
                    src={conv.role === 'user' ? 'https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-Clipart.png' : Stellify}
                    width={40}
                    height={40}
                    alt=""
                  />
                  <div className="details" style={{ marginTop: '0px' }}>
                    <h2 style={{ margin: '0px', fontSize: 14 }}>{conv.role === 'user' ? 'You' : 'Stellify Bot'}</h2>
                    {conv.parts && conv.parts.map((part, idx) => (
                      <div key={idx}>
                        {part.img ? (
                          <img src={part.img} alt="response" style={{ width: '35rem', height: '25rem', borderRadius: '0' }} />
                        ) : part.json ? (
                          <JSONTable json={part.json} />
                        ) : (
                          showTypingEffect && conv.role === 'bot' && index === conversation.length - 1 ? (
                            <TypingText text={part.text} setIsTyping={setIsTyping} onTypingComplete={handleTypingComplete} />
                          ) : (
                            <div style={{ fontSize: 14 }}>{part.text}</div>
                          )
                        )}
                      </div>
                    ))}
                    {!conv.parts && (
                      showTypingEffect && conv.role === 'bot' && index === conversation.length - 1 ? (
                        <TypingText text={conv.text} setIsTyping={setIsTyping} onTypingComplete={handleTypingComplete} />
                      ) : (
                        <div style={{ fontSize: 14 }}>{conv.text}</div>
                      )
                    )}
                  </div>
                </div>
              ))}
              {loading && (
                <div className="message" style={{ marginTop: '10px', width: '100%' }}>
                  <img src={Stellify} width={40} height={40} alt="" />
                  <div className="details" style={{ display: 'flex' }}>
                    <p style={{ fontSize: 14 }}>{'Stellify Bot'}</p>
                  </div>
                  <div className="loader"></div>
                </div>
              )}
            </div>
          ) : (
            <div className="nochat">
              <img
                style={{ marginLeft: 100 }}
                src={Stellify}
                width={60}
                height={60}
                alt=""
              />
              <h3 style={{ marginTop: 20 }}>How can I help you?</h3>
            </div>
          )}

          <div className="bottomsection">
            <div className="messagebar" style={{ position: 'relative' }}>
              <input
                type="text"
                value={question}
                onChange={handleQuestionChange}
                style={{ fontSize: 14 }}
                placeholder="Ask your question here..."
                onKeyPress={handleKeyPress}
              />

              <div style={{ position: 'absolute', right: '50px' }}>
                <svg
                  onClick={handleAskQuestion}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                  style={{ cursor: question.trim() === '' ? 'not-allowed' : 'pointer' }} // Disable click if input is empty
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TypingText = ({ text = '', setIsTyping, onTypingComplete }) => {
  const [displayText, setDisplayText] = useState('');
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (!text) {
      setIsTyping(false);
      onTypingComplete();
      return;
    }

    setIsTyping(true);
    const typingInterval = setInterval(() => {
      if (index < text.length) {
        setDisplayText(text.slice(0, index + 1));
        setIndex(index + 1);
      } else {
        clearInterval(typingInterval);
        setIsTyping(false);
        onTypingComplete();
      }
    }, 20); // Adjust typing speed here

    return () => {
      clearInterval(typingInterval);
    };
  }, [text, index]);

  return <div style={{ fontSize: 14, lineHeight: '20px' }} className="chat-text-style">{displayText}</div>;
};

const JSONTable = ({ json }) => (
  <table className="table table-bordered">
    <thead>
      <tr>
        {Object.keys(json[0]).map((key) => (
          <th key={key}>{key}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {json.map((item, index) => (
        <tr key={index}>
          {Object.values(item).map((value, idx) => (
            <td style={{ fontSize: 12 }} key={idx}>{value}</td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);

export default ChatpageDatabase;
