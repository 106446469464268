// src/pages/LoginScreen.js
import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import "./LoginScreen.css";
import Header from './Header';
import { nanoid } from 'nanoid'; // Import nanoid
import { toast, ToastContainer } from 'react-toastify'; // Import react-toastify

const API_URL = 'https://chatbotnodeapi.stellifyflows.in/chat-bot/users/login';

const LoginScreen = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(API_URL, { email, password });

      if (response.data.Token) {
        localStorage.setItem('token', response.data.Token);
        
        const userData = response.data.data[0];
        localStorage.setItem('role_name', userData.role_name); // Store role name

        // Get the user's initial from the username and store it
        const userName = userData.username; // Use the username field
        const userInitial = userName ? userName.charAt(0).toUpperCase() : 'U';
        localStorage.setItem('user_initial', userInitial); // Store the initial

        if (userData.role_name === 'Admin') {
          navigate('/homepage-document-bot1');
        } else if (userData.role_name === 'Super Admin') {
          navigate(`/chatpage-documents/${nanoid()}`); // Add nanoid to the path
        }
      } else {
        toast.error('Login failed. Please try again.');
      }
    } catch (err) {
      if (err.response && err.response.data) {
        toast.error(err.response.data.message || 'An error occurred. Please try again.');
      } else {
        toast.error('An unexpected error occurred. Please try again.');
      }
    }
  }, [email, password, navigate]);

  return (
    <div>
      <Header />
      <div className='container'>
        <div className='login-screen'>
          <div className="main-boduy1">
            <div className="left-content1">
              <div className="stellify-bot-wrapper">
                <div style={{ fontSize: 14 }} className="stellify-bot">Stellify BOT</div>
              </div>
              <b className="a-fast-chatbot">{`A Fast ChatBot & Scalable AI`}</b>
              <div style={{ fontSize: 14 }} className="our-technology-performing">
                Our technology performing fast ChatBot (120K TPS) and it has
                guaranteed AI-based data security. Proof of Stake, its consensus
                algorithm enables unlimited speeds.
              </div>
            </div>
            <div className="login-form">
              <form onSubmit={handleSubmit}>
                <div className="form-field5" style={{ marginBottom: "10px" }}>
                  <div style={{ fontSize: 14 }} className="email-address">Email address</div>
                  <div>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter your email"
                      className="enter-your-email-wrapper"
                      required
                      style={{ fontSize: 14 }}
                    />
                  </div>
                </div>
                <div className="form-field5" style={{ marginBottom: "10px" }}>
                  <div style={{ fontSize: 14 }} className="email-address">Password</div>
                  <div>
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Enter your password"
                      className='enter-your-email-wrapper'
                      required
                      style={{ fontSize: 14 }}
                    />
                  </div>
                </div>
                <div className="form-field7">
                  <button type="submit" className="submit-wrapper">
                    <div className="submit">Submit</div>
                  </button>
                </div>
              </form>
            </div>
            <div className="main-boduy-child" />
          </div>
        </div>
      </div>
      <ToastContainer /> {/* Ensure ToastContainer is included */}
    </div>
  );
};

export default LoginScreen;
