// src/components/NavBar.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import './NavBar.css'; // Ensure this file contains the styles for unique colors

const NavBar = () => {
  const navigate = useNavigate();
  
  // Retrieve user initial from localStorage
  const userInitial = localStorage.getItem('user_initial') || 'U'; // Default to 'U' if not found
  
  // Generate a unique color for the user initial
  const generateColor = (initial) => {
    const colors = ['#FF5733', '#33FF57', '#3357FF', '#FF33A1', '#33FFA1', '#A133FF'];
    const index = initial.charCodeAt(0) % colors.length; // Use char code to index colors
    return colors[index];
  };

  const initialColor = generateColor(userInitial);

  const onUserContainerClick = () => {
    navigate("/");
  };

  return (
    <div className="header">
      <div className="logo">
        <img className="layer-1-icon" alt="" src="/layer-1@2x.png" />
        <div className="bot"></div>
      </div>
      <div className="menu">
        <div style={{ fontSize: 14, cursor: "pointer" }} className="bot">About us</div>
        <div style={{ fontSize: 14, cursor: "pointer" }} className="bot">Contact us</div>
        <div className="user" onClick={onUserContainerClick}>
          <div className="bot" style={{ backgroundColor: initialColor, padding: '10px 15px', borderRadius: '50%', color: '#fff' }}>
            {userInitial}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
