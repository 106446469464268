// src/ChatpageDocuments.js

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Stellify from '../images/Stellify.png';
import { useNavigate, useParams } from 'react-router-dom';
import './ChatpageDocuments.css';
import NavBar from '../components/NavBar';
import LeftSection from '../components/LeftSection';

const API_BASE_URL = 'https://chatbotpdfapi.stellifyflows.in/';

const ChatpageDocuments = () => {
  const { sessionId } = useParams(); // Extract sessionId from URL parameters
  const [question, setQuestion] = useState('');
  const [conversation, setConversation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showTypingEffect, setShowTypingEffect] = useState(false);
  const playAreaRef = useRef(null);
  const navigate = useNavigate();

  // Load chat history from localStorage based on sessionId
  useEffect(() => {
    const storedConversation = JSON.parse(localStorage.getItem(`chat_${sessionId}`)) || [];
    setConversation(storedConversation);
    setShowTypingEffect(false); // Disable typing effect for existing conversations
  }, [sessionId]);

  // Store conversation to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem(`chat_${sessionId}`, JSON.stringify(conversation));
    // Notify LeftSection to update chat history
    window.dispatchEvent(new Event('updateChatHistory'));
  }, [conversation, sessionId]);

  const scroll = () => {
    if (playAreaRef.current) {
      playAreaRef.current.scrollTop = playAreaRef.current.scrollHeight; // Scroll to the bottom
    }
  };

  useEffect(() => {
    scroll();
  }, [conversation]); // Trigger scrolling when conversation changes

  const handleQuestionChange = (event) => {
    setQuestion(event.target.value);
  };

  const handleAskQuestion = async () => {
    if (question.trim() === '') return;

    setLoading(true);
    const userMessage = { role: 'user', text: question, type: 'documents' };
    setConversation((prevConversation) => [...prevConversation, userMessage]);
    setQuestion('');

    try {
      const response = await axios.post(`${API_BASE_URL}api/question`, { question });
      const answer = response.data.answer;
      const botMessage = { role: 'bot', text: answer, type: 'documents' };
      setShowTypingEffect(true); // Enable typing effect for new responses
      setConversation((prevConversation) => [...prevConversation, botMessage]);
    } catch (error) {
      console.error('Error getting the answer:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !loading && question.trim() !== '') {
      handleAskQuestion();
    }
  };

  return (
    <div className="chatpage-documents">
      <NavBar />
      <div className="main-boduy3">
        <LeftSection />
        <div className="right-content2">
          {conversation.length > 0 ? (
            <div className="play-area2" ref={playAreaRef}> {/* Add ref here */}
              {conversation.map((conv, index) => (
                <div key={index} className="message" style={{ width: '100%', marginBottom: '10px' }}>
                  <img
                    src={conv.role === 'user' ? 'https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-Clipart.png' : Stellify}
                    width={40}
                    height={40}
                    alt=""
                  />
                  <div className="details" style={{ marginTop: '0px' }}>
                    <h2 style={{ margin: '0px', fontSize: 14 }}>{conv.role === 'user' ? 'You' : 'Stellify Bot'}</h2>
                    {showTypingEffect && conv.role === 'bot' && index === conversation.length - 1 ? (
                      <TypingText text={conv.text} />
                    ) : (
                      <div style={{ fontSize: 14 }}>{conv.text}</div>
                    )}
                  </div>
                </div>
              ))}
              {loading && (
                <div className="message" style={{ marginTop: '10px', width: '100%' }}>
                  <img src={Stellify} width={40} height={40} alt="" />
                  <div className="details" style={{ display: 'flex' }}>
                    <p style={{ fontSize: 14 }}>{'Stellify Bot'}</p>
                  </div>
                  <div className="loader"></div>
                </div>
              )}
            </div>
          ) : (
            <div className="nochat">
              <img
                style={{ marginLeft: 100 }}
                src={Stellify}
                width={60}
                height={60}
                alt=""
              />
              <h3 style={{ marginTop: 20 }}>How can I help you?</h3>
            </div>
          )}

          <div className="bottomsection">
            <div className="messagebar" style={{ position: 'relative' }}>
              <input
                type="text"
                value={question}
                onChange={handleQuestionChange}
                style={{ fontSize: 14 }}
                placeholder="Ask your question here..."
                onKeyPress={handleKeyPress}
              />

              <div style={{ position: 'absolute', right: '50px' }}>
                <svg
                  onClick={handleAskQuestion}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                  style={{ cursor: question.trim() === '' ? 'not-allowed' : 'pointer' }} // Disable click if input is empty
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TypingText = ({ text = '' }) => {
  const [displayText, setDisplayText] = useState('');
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const typingInterval = setInterval(() => {
      if (index <= text.length) {
        setDisplayText(text.slice(0, index));
        setIndex(index + 1);
      } else {
        clearInterval(typingInterval);
      }
    }, 20);

    return () => {
      clearInterval(typingInterval);
    };
  }, [text, index]);

  return <div style={{ fontSize: 14, lineHeight: '20px' }} className="chat-text-style">{displayText}</div>;
};

export default ChatpageDocuments;
