// src/App.js

import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import HomepageDatabaseBOT from "./pages/HomepageDatabaseBOT";
import LoginScreen from "./pages/LoginScreen";
import HomepageDatabaseBOT1 from "./pages/HomepageDatabaseBOT1";
import ChatpageDocuments from "./pages/ChatpageDocuments";
import HomepageDocumentBOT from "./pages/HomepageDocumentBOT";
import ChatpageDatabase from "./pages/ChatpageDatabase";
import HomepageDocumentBOT1 from "./pages/HomepageDocumentBOT1";
import ProtectedRoute from "./components/ProtectedRoute";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "Welcome to the login page.";
        break;
      case "":
        title = "Database BOT";
        metaDescription = "Manage your database connections.";
        break;
      case "":
        title = "Database BOT";
        metaDescription = "Manage your database connections.";
        break;
      case "":
        title = "Documents Chat";
        metaDescription = "Chat with your document bots.";
        break;
      case "":
        title = "Manage Documents BOT";
        metaDescription = "Manage your document bots.";
        break;
      case "":
        title = "Database Chat";
        metaDescription = "Chat with your database bots.";
        break;
      case "":
        title = "Document BOT";
        metaDescription = "Manage your document connections.";
        break;
      default:
        title = "";
        metaDescription = "Welcome to the application.";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<LoginScreen />} />
      <Route path="/homepage-database-bot1" element={<ProtectedRoute><HomepageDatabaseBOT /></ProtectedRoute>} />
      <Route path="/homepage-database-bot" element={<ProtectedRoute><HomepageDatabaseBOT1 /></ProtectedRoute>} />
      <Route path="/chatpage-documents/:sessionId" element={<ProtectedRoute><ChatpageDocuments /></ProtectedRoute>} /> {/* Ensure sessionId param */}
      <Route path="/homepage-document-bot1" element={<ProtectedRoute><HomepageDocumentBOT /></ProtectedRoute>} />
      <Route path="/chatpage-database/:sessionId" element={<ProtectedRoute><ChatpageDatabase /></ProtectedRoute>} /> {/* No sessionId needed */}
      <Route path="/homepage-document-bot" element={<ProtectedRoute><HomepageDocumentBOT1 /></ProtectedRoute>} />
    </Routes>
  );
}

export default App;
