import { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify"; // Import toast from react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Import react-toastify CSS
import "./HomepageDatabaseBOT.css";
import NavBar from "../components/NavBar";
import LeftSection from "../components/LeftSection";

const HomepageDatabaseBOT = () => {
  const navigate = useNavigate();
  const [dbConnections, setDbConnections] = useState([]);
  const [activeConnectionIndex, setActiveConnectionIndex] = useState(null); // Track active connection index
  const [loadingIndex, setLoadingIndex] = useState(null); // Track loading state for connections
  const [newConnection, setNewConnection] = useState({
    DB_DATABASE: "",
    DB_HOST: "",
    DB_USER: "",
    DB_PASSWORD: "",
    DB_PORT: "",
    DB_ENGINE: "",
  });

  useEffect(() => {
    const fetchConnections = async () => {
      try {
        const response = await axios.get("https://stellifybot-ai-api.stellifyflows.in/get_connections");
        setDbConnections(response.data);
        
        // Restore active connection index from local storage
        const savedActiveIndex = localStorage.getItem("activeConnectionIndex");
        if (savedActiveIndex !== null) {
          setActiveConnectionIndex(Number(savedActiveIndex));
        }
      } catch (error) {
        console.error("Error fetching database connections:", error);
      }
    };

    fetchConnections();
  }, []);

  const onUserContainerClick = useCallback(() => {
    navigate("/login-screen");
  }, [navigate]);

  const onMenuItemContainerClick = useCallback(() => {
    navigate("/homepage-document-bot");
  }, [navigate]);

  const onMenuItemContainerClick1 = useCallback(() => {
    navigate("/homepage-database-bot");
  }, [navigate]);

  const onBotsContainerClick = useCallback(() => {
    navigate("/homepage-document-bot1");
  }, [navigate]);

  const handleDbTypeChange = (event) => {
    setNewConnection({
      ...newConnection,
      DB_ENGINE: event.target.value,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewConnection({
      ...newConnection,
      [name]: value,
    });
  };

  const handleConnectionClick = async (index) => {
    if (activeConnectionIndex !== null && activeConnectionIndex !== index) {
      toast.error("Already connected to one database. Please disconnect first.");
      return;
    }

    const connection = dbConnections[index];
    const params = {
      DB_HOST: connection.DB_HOST,
      DB_DATABASE: connection.DB_DATABASE,
      DB_USER: connection.DB_USER,
      DB_PASSWORD: connection.DB_PASSWORD,
      DB_PORT: connection.DB_PORT,
      DB_ENGINE: connection.DB_ENGINE,
    };

    setLoadingIndex(index); // Set the current index as loading
    toast.info("Connecting...");

    try {
      const response = await axios.post("https://stellifybot-ai-api.stellifyflows.in/connect", params);

      if (response.status === 200) {
        setActiveConnectionIndex(index); // Set active connection index
        localStorage.setItem("activeConnectionIndex", index); // Save active connection index to local storage
        toast.success("Connected successfully.");
      }
    } catch (error) {
      console.error("Error connecting to the database:", error);
      toast.error("Unable to connect to the database.");
    } finally {
      setLoadingIndex(null); // Reset loading state
    }
  };

  const handleDisconnectClick = async () => {
    try {
      const response = await axios.post("https://stellifybot-ai-api.stellifyflows.in/disconnect");

      if (response.status === 200) {
        setActiveConnectionIndex(null); // Clear active connection
        localStorage.removeItem("activeConnectionIndex"); // Remove active connection index from local storage
        toast.success("Disconnected successfully.");
      }
    } catch (error) {
      console.error("Error disconnecting from the database:", error);
      toast.error("Failed to disconnect.");
    }
  };

  const handleAddConnection = async () => {
    try {
      if (
        !newConnection.DB_DATABASE ||
        !newConnection.DB_HOST ||
        !newConnection.DB_USER ||
        !newConnection.DB_PASSWORD ||
        !newConnection.DB_PORT ||
        !newConnection.DB_ENGINE
      ) {
        toast.error("Please fill all the fields.");
        return;
      }

      const response = await axios.post("https://stellifybot-ai-api.stellifyflows.in/add_connection", newConnection);

      if (response.status === 200) {
        const updatedConnections = await axios.get("https://stellifybot-ai-api.stellifyflows.in/get_connections");
        setDbConnections(updatedConnections.data);

        setNewConnection({
          DB_DATABASE: "",
          DB_HOST: "",
          DB_USER: "",
          DB_PASSWORD: "",
          DB_PORT: "",
          DB_ENGINE: "",
        });

        toast.success("Database added successfully.");
      }
    } catch (error) {
      console.error("Error adding new database connection:", error);
      toast.error("Failed to add database.");
    }
  };

  const handleDeleteConnection = async (index) => {
    const connection = dbConnections[index];
    const params = {
      DB_DATABASE: connection.DB_DATABASE,
      DB_ENGINE: connection.DB_ENGINE,
      DB_HOST: connection.DB_HOST,
      DB_PASSWORD: connection.DB_PASSWORD,
      DB_PORT: connection.DB_PORT,
      DB_USER: connection.DB_USER,
    };

    try {
      const response = await axios.delete("https://stellifybot-ai-api.stellifyflows.in/delete_connection", {
        data: params,
      });

      if (response.status === 200) {
        const updatedConnections = await axios.get("https://stellifybot-ai-api.stellifyflows.in/get_connections");
        setDbConnections(updatedConnections.data);
        toast.success("Database connection deleted successfully.");
      }
    } catch (error) {
      console.error("Error deleting the database connection:", error);
      toast.error("Failed to delete database connection.");
    }
  };

  return (
    <div className="homepage-database-bot">
      <NavBar />
      <div className="main-boduy">
        <LeftSection />
        <div className="right-content">
          <div className="play-area">
            <div className="document-selected">
              <div className="vector-wrapper">
                <img className="vector-icon" alt="" src="/vector.svg" />
              </div>
              <div style={{ fontSize: 16 }} className="bot">
                Manage Databases
              </div>
            </div>
            <div className="upload-documents">
              <div className="form-field-parent">
                <div className="form-field">
                  <div className="bot">Database Type</div>
                  <div>
                    <select
                      value={newConnection.DB_ENGINE}
                      onChange={handleDbTypeChange}
                      className="select-db-type"
                      name="DB_ENGINE"
                    >
                      <option value="" disabled>
                        Select DB Type
                      </option>
                      <option value="mssql">mssql</option>
                      <option value="mysql">mysql</option>
                      <option value="mongodb">MongoDB</option>
                      <option value="mariadb">MariDB</option>
                    </select>
                  </div>
                </div>
                <div className="form-field">
                  <div className="bot">Database Name</div>
                  <div >
                    <input
                      type="text"
                      name="DB_DATABASE"
                      value={newConnection.DB_DATABASE}
                      onChange={handleInputChange}
                      className="enter-server-name"
                      placeholder="Enter Database Name"
                      style={{ borderRadius: "4px", width: 200 }}
                    />
                  </div>
                </div>
                <div className="form-field">
                  <div className="bot">Host Name</div>
                  <div >
                    <input
                      type="text"
                      name="DB_HOST"
                      value={newConnection.DB_HOST}
                      onChange={handleInputChange}
                      className="enter-server-name"
                      placeholder="Enter Host Name"
                      style={{ borderRadius: "4px", width: 200 }}
                    />
                  </div>
                </div>
                <div className="form-field">
                  <div className="bot">Username</div>
                  <div >
                    <input
                      type="text"
                      name="DB_USER"
                      value={newConnection.DB_USER}
                      onChange={handleInputChange}
                      className="enter-server-name"
                      placeholder="Enter Username"
                      style={{ borderRadius: "4px", width: 200 }}
                    />
                  </div>
                </div>
                <div className="form-field">
                  <div className="bot">Password</div>
                  <div >
                    <input
                      type="password"
                      name="DB_PASSWORD"
                      value={newConnection.DB_PASSWORD}
                      onChange={handleInputChange}
                      className="enter-server-name"
                      placeholder="Enter Password"
                      style={{ borderRadius: "4px", width: 200 }}
                    />
                  </div>
                </div>
                <div className="form-field">
                  <div className="bot">Port</div>
                  <div >
                    <input
                      type="text"
                      name="DB_PORT"
                      value={newConnection.DB_PORT}
                      onChange={handleInputChange}
                      className="enter-server-name"
                      placeholder="Enter Port"
                      style={{ borderRadius: "4px", width: 200 }}
                    />
                  </div>
                </div>
              </div>
              <div className="menu-item2" onClick={handleAddConnection}>
                <div className="add-wrapper">
                  <div className="add">Add</div>
                </div>
              </div>
            </div>
            <div className="documents1">
              <div className="title">
                <div style={{ fontSize: 14 }} className="bot">
                  Database Connections
                </div>
              </div>
              <div className="table-th-parent">
                <div className="table-th">
                  <div className="th-item">
                    <div style={{ fontSize: 12 }} className="add">
                      S.no
                    </div>
                  </div>
                  <div className="th-item1">
                    <div style={{ fontSize: 12 }} className="database-name">
                      Database Name
                    </div>
                    <div style={{ fontSize: 12 }} className="database-name">
                      Host Name
                    </div>
                    <div style={{ fontSize: 12 }} className="database-name">
                      DB Type
                    </div>
                    <div style={{ fontSize: 12 }} className="database-name">
                      Username
                    </div>
                    <div style={{ fontSize: 12 }} className="database-name">
                      Password
                    </div>
                    <div style={{ fontSize: 12 }} className="database-name">
                      Port
                    </div>
                  </div>
                  <div className="th-item2">
                    <div style={{ fontSize: 12 }} className="database-name">
                      Actions
                    </div>
                  </div>
                </div>
                {dbConnections.map((connection, index) => (
                  <div key={index} className="table-th">
                    <div className="th-item">
                      <div style={{ fontSize: 11 }} className="div">
                        {index + 1}
                      </div>
                    </div>
                    <div className="th-item1">
                      <div style={{ fontSize: 12 }} className="analysis-db">
                        {connection.DB_DATABASE}
                      </div>
                      <div style={{ fontSize: 12 }} className="analysis-db">
                        {connection.DB_HOST}
                      </div>
                      <div style={{ fontSize: 12 }} className="analysis-db">
                        {connection.DB_ENGINE}
                      </div>
                      <div style={{ fontSize: 12 }} className="analysis-db">
                        {connection.DB_USER}
                      </div>
                      <div style={{ fontSize: 12 }} className="analysis-db">
                        {connection.DB_PASSWORD}
                      </div>
                      <div style={{ fontSize: 12 }} className="analysis-db">
                        {connection.DB_PORT}
                      </div>
                    </div>
                    <div className="th-item5">
                      <button
                        onClick={() => handleDeleteConnection(index)}
                        style={{
                          background: "none",
                          border: "none",
                          cursor: "pointer",
                          marginRight: "8px",
                        }}
                      >
                        <img
                          src="/delete-24dp-5f6368-fill0-wght400-grad0-opsz24-1-1.svg"
                          alt="Delete"
                          style={{ width: "20px", height: "20px" }}
                        />
                      </button>
                      <button
                        style={{
                          fontSize: 12,
                          backgroundColor:
                            activeConnectionIndex === index ? "red" : "green",
                          color: "white",
                          border: "none",
                          padding: "5px 10px",
                          cursor: "pointer",
                          borderRadius: "4px",
                        }}
                        onClick={
                          activeConnectionIndex === index
                            ? handleDisconnectClick
                            : () => handleConnectionClick(index)
                        }
                        disabled={loadingIndex === index} // Disable the button if it's loading
                      >
                        {activeConnectionIndex === index
                          ? "Disconnect"
                          : loadingIndex === index
                          ? "Connecting..." // Show connecting text if the button is loading
                          : "Connect"}
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="qtn-area">
            <div className="bot">Ask your question here...</div>
            <img
              className="send-24dp-5f6368-fill0-wght400-icon"
              alt=""
              src="/send-24dp-5f6368-fill0-wght400-grad0-opsz24-1.svg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomepageDatabaseBOT;
