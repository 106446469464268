import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import "./HomepageDocumentBOT1.css";
import NavBar from '../components/NavBar';
import LeftSection from '../components/LeftSection';

const API_BASE_URL = 'https://savan.stellifyflows.in/';

const HomepageDocumentBOT1 = () => {
  const [documents, setDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const roleName = location.state?.role_name;

  useEffect(() => {
    fetchDocuments();
  }, []);

  const fetchDocuments = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found');

      const response = await axios.get(`${API_BASE_URL}api/documents`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const documentsData = response.data;
      setDocuments(documentsData);
      if (documentsData.length > 0) {
        setSelectedDocument(documentsData[0].id);
      }
    } catch (error) {
      console.error('Error fetching documents:', error.response ? error.response.data : error.message);
    }
  };

  const onUserContainerClick = useCallback(() => {
    navigate("/login-screen");
  }, [navigate]);

  const onMenuItemContainerClick = useCallback(() => {
    navigate("/homepage-database-bot");
  }, [navigate]);

  const onBotsContainerClick = useCallback(() => {
    navigate("/homepage-document-bot1");
  }, [navigate]);

  const onBotsContainerClick1 = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onFormFieldContainerClick = useCallback(() => {
    navigate("/chatpage-documents", { state: { selectedDocument, documents, roleName } });
  }, [navigate, selectedDocument, documents, roleName]);

  const handleDocumentClick = (doc) => {
    setSelectedDocument(doc.id);
  };

  return (
    <div className="homepage-document-bot1">
      <NavBar />
      <div className="main-boduy6">
        <LeftSection />
        <div className="right-content5">
          <div className="play-area5">
            <div className="document-selected5">
              <div className="vector-wrapper6">
                <img className="vector-icon40" alt="" src="/vector.svg" />
              </div>
              <div style={{fontSize:14}} className="choose-document">Choose Document</div>
            </div>
            <div className="documents9">
              {documents.map((doc) => (
                <div
                style={{marginTop:20}}
                  key={doc.id}
                  className={`document-tile4 ${selectedDocument === doc.id ? 'selected' : ''}`}
                  onClick={() => handleDocumentClick(doc)}
                >
                  <div  style={{fontSize:12, color: selectedDocument === doc.id ? 'white' : 'black', cursor: "pointer"}} className="choose-document">{doc.name}</div>
                </div>
              ))}
            </div>
            <div className="form-field9" onClick={onFormFieldContainerClick}>
              <div className="ask-questions-group">
                <div  style={{fontSize:14}}className="ask-questions1">Chat with Document</div>
                <img
                  className="login-24dp-5f6368-fill0-wght40-icon1"
                  alt=""
                  src="/login-24dp-5f6368-fill0-wght400-grad0-opsz24-1.svg"
                />
              </div>
            </div>
          </div>
          <div className="qtn-area5">
            <div className="choose-document">Ask your question here...</div>
            <img
              className="send-24dp-5f6368-fill0-wght400-icon5"
              alt=""
              src="/send-24dp-5f6368-fill0-wght400-grad0-opsz24-1.svg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomepageDocumentBOT1;
